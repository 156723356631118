import { neverland as $, render, html, useState } from "neverland";

import Loader from "./Loader.js";
import LoginPage from "./LoginPage.js";

function loadComponent(importer, componentName, components, setComponents) {
	// Dynamically load components using Parcel code splitting.
	// https://parceljs.org/features/code-splitting/

	if (!components.hasOwnProperty(componentName)) {
		importer.then(function (component) {
			setComponents((prevComponents) => {
				let components = { ...prevComponents };
				components[componentName] = component.default;
				return components;
			});
		});

		return Loader;
	} else {
		return components[componentName];
	}
}

// Import available slugs.
import comments from "../specs/comments.js";
import histograms from "../specs/histograms.js";
import slopegraphs from "../specs/slopegraphs.js";
import stackedbars from "../specs/stackedbars.js";
import scatterplots from "../specs/scatterplots.js";
import mentalhealths from "../specs/mentalhealths.js";

const Router = $(function (auth, setAuth, theme) {
	let path = window.location.pathname.split("/").filter((d) => d != "");
	path = path.length == 0 ? ["home"] : path;

	// Set up object to store dynamically imported components.
	const [components, setComponents] = useState({});
	let Report, Item;

	switch (auth.isLoggedIn) {

		case true: // (logged in)
		
			switch (path[0]) {
		
				case "home":
				case "login":
		
					if (path[0] == "login") {
						// Redirect to homepage.
						window.history.pushState({}, "", "/");
					}

					let Home = loadComponent(
						import("./Home.js"),
						"Home",
						components,
						setComponents
					);

					document.title = "Home";
					return html`${Home(auth, setAuth, theme)}`;
					break;

				case "data":
					
					let Data = loadComponent(
						import("./Data.js"),
						"Data",
						components,
						setComponents
					);
					document.title = "Data";

					function slugIn(...array) {
						if (Array.isArray(array[0])) {
							array = array[0];
						}
						return array.includes(path[2]);
					}

					// TODO - Currently some page slugs are hard coded. They probably should be stored in database
					// and loaded dynamically.

					if (path.length == 2 & theme.surveys.map(d => d.id).includes(path[1])) {

						let survey = theme.surveys.filter(d => d.id == path[1])[0];
						document.title = `${survey.name} Survey`;
						Report = loadComponent(import('./SurveyLandingPage.js'), 'SurveyLandingPage', components, setComponents);
						return html`${Data(auth, setAuth, theme, path, Report)}`;

					} else if (slugIn('pdf-mentalhealth', 'pdf-annualoutcomes')) {
						
						document.title = `${path[2].endsWith('annualoutcomes') ? "TOMS": "Mental Health"} Report (PDF)`
						Report = loadComponent(
							import("../reports/MentalHealthPDF.js"),
							"MentalHealthPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn('action-plans')) {
						
						document.title = `Action Plans`;
						Report = loadComponent(
							import("../reports/ActionPlans.js"),
							"ActionPlan",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (
						slugIn(
							"pdf-snapshot",
							"pdf-snapshot-short",
							"pdf-snapshot-ipe-mi7",
							"pdf-snapshot-learning-curve-wellbeing",
							"pdf-snapshot-ks"
						)
					) {
						
						document.title = "Snapshot Report (PDF)";
						Report = loadComponent(
							import("../reports/SnapshotPDF.js"),
							"SnapshotPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (
						slugIn(
							"snapshot",
							"snapshot-short",
							"snapshot-ipe-mi7",
							"snapshot-learning-curve-wellbeing",
							"snapshot-ks"
						)
					) {
						
						document.title = "Snapshot Report";
						Report = loadComponent(
							import("../reports/Snapshot.js"),
							"Snapshot",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn(
							"pdf-resilience",
							"pdf-healthy-mind",
							"pdf-ready-to-learn"
						)) {
					
						document.title = "The Positive Learners Mindset (PDF)";
						Report = loadComponent(
							import("../reports/ScatterplotPDF.js"),
							"ScatterplotPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn(scatterplots.map((d) => d.key))) {
					
						Item = scatterplots.filter((d) => path[2] == d.key)[0];
						document.title = `${Item.key} - ${Item.title}`;
						Report = loadComponent(
							import("../reports/Scatterplot.js"),
							"Scatterplot",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn("pdf-overview", "pdf-overview-learning-curve")) {
					
						document.title = "Overview Report (PDF)";
						Report = loadComponent(
							import("../reports/OverviewPDF.js"),
							"OverviewPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (
						slugIn(
							"executive-summary",
							"executive-summary-short",
							"executive-summary-ipe-mi7",
							"executive-summary-learning-curve-wellbeing"
						)
					) {
					
						document.title = "Executive Summary Reports";
						Report = loadComponent(
							import("../reports/ExecSumHelper.js"),
							"ExecSumHelper",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (
						slugIn(
							"pdf-cross-sectional",
							"pdf-cross-sectional-short",
							"pdf-cross-sectional-ipe-mi7",
							"pdf-cross-sectional-learning-curve-wellbeing"
						)
					) {
					
						document.title = "Cross-Sectional Report (PDF)";
						Report = loadComponent(
							import("../reports/ExecSumPDF.js"),
							"ExecSumPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (
						slugIn(
							"pdf-longitudinal",
							"pdf-longitudinal-short",
							"pdf-longitudinal-ipe-mi7",
							"pdf-longitudinal-learning-curve-wellbeing"
						)
					) {
					
						document.title = "Longitudinal Report (PDF)";
						Report = loadComponent(
							import("../reports/ExecSumPDF.js"),
							"ExecSumPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (
						slugIn(
							"pdf-time-lag",
							"pdf-time-lag-short",
							"pdf-time-lag-ipe-mi7",
							"pdf-time-lag-learning-curve-wellbeing"
						)
					) {
					
						document.title = "Time-Lag Report (PDF)";
						Report = loadComponent(
							import("../reports/ExecSumPDF.js"),
							"ExecSumPDF",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;

					} else if (slugIn('pdf-demographics')) {
					
						document.title = 'Demographics Report (PDF)';
						Report = loadComponent(import('../reports/DemographicsPDF.js'), 'DemographicsPDF', components, setComponents);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn('pdf-swat')) {
				
						document.title = 'SWAT Report (PDF)';
						Report = loadComponent(import('../reports/SWATPDF.js'), 'SWATPDF', components, setComponents);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
				
					} else if (slugIn(
							'gemfinder-walkthrough'
						)) {

						document.title = 'Gemfinder Walkthrough';
						Report = loadComponent(import('../reports/GemfinderWalkthrough.js'), 'GemfinderWalkthrough', components, setComponents);
						return html`${Data(auth, setAuth, theme, path, Report)}`;

					} else if (slugIn(
							'gemfinder'
						)) {

						document.title = 'Gemfinder';
						Report = loadComponent(import('../reports/Gemfinder.js'), 'Gemfinder', components, setComponents);
						return html`${Data(auth, setAuth, theme, path, Report)}`;

					} else if (slugIn(histograms.map((d) => d.key))) {
					
						Item = histograms.filter((d) => path[2] == d.key)[0];
						document.title = `${Item.key} - ${Item.text}`;
						Report = loadComponent(
							import("../reports/Histogram.js"),
							"Histogram",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn(comments.map((d) => d.key))) {
					
						Item = comments.filter((d) => path[2] == d.key)[0];
						document.title = `${Item.key} - ${(Item.key == 'comments' && auth.groups.hasOwnProperty('surveyPhillipsFoundation')) ? "Your Custom Comments Question" : Item.title}`;
						Report = loadComponent(
							import("../reports/Comments.js"),
							"Comments",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn(slopegraphs.map((d) => d.key))) {
					
						Item = slopegraphs.filter((d) => path[2] == d.key)[0];
						document.title = `${Item.key} - ${Item.title}`;
						Report = loadComponent(
							import("../reports/Slopegraph.js"),
							"Slopegraph",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else if (slugIn(stackedbars.map((d) => d.key))) {
					
						Item = stackedbars.filter((d) => path[2] == d.key)[0];
						document.title = `${Item.key} - ${Item.title}`;
						Report = loadComponent(
							import("../reports/StackedBar.js"),
							"StackedBar",
							components,
							setComponents
						);
						return html`${Data(auth, setAuth, theme, path, Report)}`;
					
					} else {
					
						return html`${Data(auth, setAuth, theme)}`;
					
					}

					break;

				case "plan":
					
					let Plans = loadComponent(
						import("./Plans.js"),
						"Plans",
						components,
						setComponents
					);
					document.title = "Plans";
					
					slugIn = function(...array) {
						if (Array.isArray(array[0])) {
							array = array[0];
						}
						return array.includes(path[1]);
					}

					 if (slugIn('action-plans')) {
						
						document.title = `Action Plans`;
						Report = loadComponent(
							import("../reports/ActionPlans.js"),
							"ActionPlans",
							components,
							setComponents
						);
						return html`${Plans(auth, setAuth, theme, path, Report)}`;
					
					} else {
					
						return html`${Plans(auth, setAuth, theme)}`;
					
					}

				case "resources":
					
					let FreeResources = loadComponent(
						import("./FreeResources.js"),
						"FreeResources",
						components,
						setComponents
					);
					document.title = "Free Resources";
					return html`${FreeResources(auth, setAuth, theme)}`;
					break;

				default:
					
					let Error404 = loadComponent(
						import("./Error404.js"),
						"Error404",
						components,
						setComponents
					);
					document.title = "404 Error";
					return html`${Error404(auth, setAuth, theme)}`;
					break;
			
			}

		case false: // (not logged in)
			
			document.title = "Login";
			return html`${LoginPage(auth, setAuth, theme)}`;
			break;

		default:
			
			document.title = "Login";
			return html`${LoginPage(auth, setAuth, theme)}`;
			break;
	}
});

export default Router;
